<template>
  <div>
    <main class="main-section">
      <section class="dsa-form">
        <div class="container">
          <span class="dsa-title">DSA Form Application</span>
          <div id="dsa_forms" class="dsa-form-collapsible">
            <div class="collapse show form-step" id="personal_details" aria-labelledby="step_1" data-bs-parent="#dsa_forms">
              <span class="step-heading" id="step_1">Step 1/3</span>
              <h1 class="dsa-form-section-title">Please Fill Personal Details</h1>
              <form @submit.prevent="goToStep2">
                <div class="row">
                  <fieldset class="form-group col-md-6">
                    <label for="full_name">Name *</label>
                    <input type="text" v-model="new_registration.name" required class="form-control" placeholder="Enter Full Name" />
                  </fieldset>
                  <fieldset class="form-group col-md-6">
                    <label for="full_name">Firm Name </label>
                    <input type="text" v-model="new_registration.firm_name" class="form-control" placeholder="Enter Firm Name" />
                  </fieldset>
                </div>
                <div class="row">
                  <fieldset class="form-group col-md-6">
                    <label for="mobile_number">Mobile Number *</label>
                    <input type="text" required v-model="new_registration.mobile" class="form-control" placeholder="Enter Mobile Number" pattern="^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$" ref="phone_number_element" />
                  </fieldset>
                  <fieldset class="form-group col-md-6">
                    <label for="email_address">Email Address *</label>
                    <input type="email" v-model="new_registration.email_id" required class="form-control" placeholder="Enter Email Address" />
                  </fieldset>
                  <fieldset class="form-group col-md-6">
                    <label for="aadhaar_number">Aadhaar Number</label>
                    <input type="text" required v-model="new_registration.aadhar_number" class="form-control" placeholder="Enter Aadhaar Number" pattern="^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$" ref="aadhar_element" />
                  </fieldset>
                  <fieldset class="form-group col-md-6">
                    <label for="location">Location *</label>
                    <multiselect v-model="new_registration.location" :searchable="true" placeholder="Select Location" class="form-multiselect" trackBy="name" :options="locationList" label="name" valueProp="id" :multiple="false" :taggable="false" :required="true"></multiselect>
                  </fieldset>
                </div>
                <div class="row">
                  <fieldset class="col-md-6"></fieldset>
                  <fieldset class="form-group col-md-6 ">
                    <label for="aadhaar_upload" class="upload-link" :class="{'error':!!fileErrorMessage}"><img src="@/assets/upload-link.svg" class="">
                      {{fileErrorMessage ?fileErrorMessage :(adhaar_card_attributes ? adhaar_card_attributes: "Upload Aadhaar Card Photo")}}</label>
                    <input type="file" ref="file" id="aadhaar_upload" hidden @change="selectedFile" />
                  </fieldset>
                </div>
                <div class="row submit-section">
                  <div class="col-lg-2">
                    <button type="submit" @click="checkValidity" class="btn btn-block step-button">Next</button>
                  </div>
                  <div class="col-12 col-lg-10">
                    <!-- <fieldset class="form-check form-switch">
                    <input class="form-check-input" v-model="new_registration.share_adhaar" type="checkbox" role="switch" id="share_aadhaar_details">
                    <label class="form-check-label" for="share_aadhaar_details">I agree to share my Aadhaar
                      details</label>
                  </fieldset> -->
                    <span class="check-details">*<span class="dsa-text-light"> This mobile number and email address will
                        be
                        used for communication
                        purposes.</span></span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <img src="@/assets/dsa-background.png" class="dsa-background-image" />
      <footer class="d-block d-md-none d-lg-none">
        <ul class="footer-links">
          <li class="nav-item">
            <a href="https://propertypistol.com/privacy-policy" target="_blank" class="nav-link">Privacy Policy</a>
          </li>
          <li class="nav-item b-none">
            <a href="https://propertypistol.com/user-agreement" target="_blank" class="nav-link">Terms and Conditions</a>
          </li>
          <li class="w-100 b-none"></li>
          <li class="nav-item">
            <a href="https://propertypistol.com/contact-us" target="_blank" class="nav-link">Contact Us</a>
          </li>
          <li class="nav-item  b-none">
            <a href="https://propertypistol.com" target="_blank" class="nav-link">© 2022 PropertyPistol Pvt. Ltd.</a>
          </li>
        </ul>
      </footer>
    </main>
  </div>
</template>
<script>
export default {
  data() {
    return {
      adhaar_card_attributes: '',
      new_registration: {
        name: null,
        firm_name: null,
        mobile: null,
        email_id: null,
        aadhar_number: null,
        aadhar_card: null,
        // share_adhaar: null
      },
      fileErrorMessage: null
    }
  },
  created() {
    this.$store.dispatch('setLoading', true);
    this.$axios.get(`dsa/settings`).then((response) => {
      let registrationSettings = {};
      if (response && response.data) {
        if (response.data.registration_types) {
          registrationSettings.registration_types = response.data.registration_types;
        }
        if (response.data.account_types) {
          registrationSettings.account_type = response.data.account_types;
        }
        if (response.data.localities && response.data.localities.length > 0) {
          registrationSettings.locations = response.data.localities;
          registrationSettings.locations.unshift({ id: null, name: 'Please select location', disabled: true });
        }
        if (response.data.users && response.data.users.length > 0) {
          registrationSettings.users = response.data.users.map(user=>({id:user.id,name:user.full_name}));
          registrationSettings.users.unshift({ id: null, name: 'Please select RM', disabled: true });
        }
      }
      this.$store.dispatch('setRegistrationSettings', registrationSettings);
      this.$store.dispatch('setLoading', false);
    }).catch(err => {
      this.$store.dispatch('setLoading', false);
    })
    let step0_data = this.$store.getters.newRegData
    if (step0_data) {
      this.new_registration = step0_data;
      if (this.new_registration.aadhar_card) {
        this.adhaar_card_attributes = this.new_registration.aadhar_card.name;
      }
    }
  },
  mounted() {
    const element = this.$refs.phone_number_element;
    element.oninvalid = () => this.checkPhoneValidity();
    element.oninput = () => element.setCustomValidity('');
    const aadhaarElement = this.$refs.aadhar_element;
    aadhaarElement.oninvalid = () => this.checkAadharValidity();
    aadhaarElement.oninput = () => aadhaarElement.setCustomValidity('');
  },
  computed: {
    locationList() {
      return this.$store.getters.registrationSettings && this.$store.getters.registrationSettings.locations;
    }
  },
  methods: {
    checkValidity() {
      this.checkPhoneValidity();
      this.checkAadharValidity();
    },
    checkPhoneValidity() {
      const element = this.$refs.phone_number_element;
      if (!element.validity.valid) {
        element.setCustomValidity('Please enter valid Phone Number');
      } else {
        element.setCustomValidity('');
      }
    },
    checkAadharValidity() {
      const element = this.$refs.aadhar_element;
      if (!element.validity.valid) {
        element.setCustomValidity('Please enter valid Aadhaar Number');
      } else {
        element.setCustomValidity('');
      }
    },
    selectedFile() {
      this.new_registration.aadhar_card = event.target.files[0];
      this.adhaar_card_attributes = this.new_registration.aadhar_card.name;
      this.fileErrorMessage = '';
    },
    goToStep2() {
      if (!this.new_registration.aadhar_card) {
        this.fileErrorMessage = "Please upload Aadhaar";
        return;
      };
      this.new_registration.step_one_complete = true;
      this.$store.dispatch('setNewRegistrationData', this.new_registration);
      this.$router.push({ name: 'Step2' });
    },
  }

}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
</style>