import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/custom.css';
import store from './store'
import axios from 'axios';
// import {token} from '@/assets/scripts/utility.js';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import VueSignaturePad from 'vue-signature-pad';
import Multiselect from '@vueform/multiselect'

const app = createApp(App);

app.use(store)
app.use(Datetime)
app.use(VueSignaturePad);
app.component("Multiselect",Multiselect);

let baseUrl="https://syndicate-network.in/public/";
if(process.env.NODE_ENV === 'production'){
  baseUrl = "https://syndicate-network.in/public/";
}
axios.defaults.baseURL = baseUrl;
axios.defaults.headers = {
  'Content-Type': 'application/json',
}
app.config.globalProperties.$axios = axios;
app.config.productionTip = false

app.use(router).mount('#app')
