import { createWebHistory, createRouter } from "vue-router";
import Step1 from "@/views/Step1.vue";
import Step2 from "@/views/Step2.vue"
import Step3 from "@/views/Step3.vue"
import store from '../store';
const routes = [
  {
    path: "/",
    name: "Step1",
    component: Step1,
  },
  {
    path: "/Step2",
    name: "Step2",
    component: Step2,
    beforeEnter:(to,from,next)=>{
      if(!store.getters.newRegData || !store.getters.newRegData.step_one_complete){
        next({name:'Step1'});
      }else{
        next();
      }
    }
  },
  {
    path: "/Step3",
    name: "Step3",
    component: Step3,
    beforeEnter:(to,from,next)=>{
      if(!store.getters.newRegData || !store.getters.newRegData.step_one_complete){
        next({name:'Step1'});
      }else if(!store.getters.newRegData.step_two_complete){
        next({name:'Step2'});
      }else{
        next();
      }
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;