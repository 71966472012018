<template>
  <div>
    <main class="main-section">
      <section class="dsa-form">
        <div class="container">
          <span class="dsa-title">DSA Form Application</span>
          <div class="dsa-form-collapsible">
            <div class="form-step">
              <form @submit.prevent="createDsaRegistration">
                <span class="step-heading" @click="step2Page()"><img src="@/assets/back-arrow.svg" width="16" height="16" class="img-fluid back-arrow" />Step 3/3</span>
                <div class="row">
                  <fieldset class="form-group col-md-6">
                    <label for="bank_name">PRPL RM</label>
                    <multiselect v-model="new_registration.rm_field" :searchable="true" placeholder="Select RM" class="form-multiselect" trackBy="name" :options="rmList" label="name" valueProp="id" :multiple="false"></multiselect>
                  </fieldset>
                </div>
                <h1 class="dsa-form-section-title">Please Fill Bank Details </h1>
                <div class="row">
                  <fieldset class="form-group col-md-6">
                    <label for="bank_name">Bank Name *</label>
                    <input type="text" v-model="new_registration.bank_name" class="form-control" placeholder="Enter Bank Name" required />
                  </fieldset>
                  <fieldset class="form-group col-md-6">
                    <label for="bank_account_number">Bank Account Number *</label>
                    <input type="text" v-model="new_registration.account_no" class="form-control" placeholder="Enter Bank Account Number" required />
                  </fieldset>
                </div>
                <div class="row">
                  <fieldset class="form-group col-md-6">
                    <label for="ifsc_code">IFSC Code *</label>
                    <input type="text" v-model="new_registration.ifsc_code" class="form-control" placeholder="Enter IFSC Code" required />
                  </fieldset>
                  <fieldset class="form-group col-md-6 radio-group" v-if="accountTypes && accountTypes.length > 0" >
                    <label class="radio-group-label">Account Type *</label>
                    <div class="form-check form-check-inline" v-for="account in accountTypes">
                      <input class="form-check-input cursor" :id="`account_${account.id}`" required v-model="new_registration.account_type" type="radio" :value="account.name">
                      <label class="form-check-label cursor" :for="`account_${account.id}`">{{account.name}}</label>
                    </div>
                    <p style="color:red;font-size: 12px;text-align:center;">{{AccountTypeErrorMsg}}</p>
                  </fieldset>
                </div>
                <div class="row">
                  <fieldset class="form-group col-md-6">
                    <label for="cancelled_cheque_upload" class="upload-link" :class="{'error':fileErrorMessage}"><img src="@/assets/upload-link.svg" class="">
                      {{fileErrorMessage? fileErrorMessage: (cancelled_cheque_attributes ? cancelled_cheque_attributes: "Upload Canceled Cheque Photo")}}</label>
                    <input type="file" ref="file" id="cancelled_cheque_upload" @change="uploadCancelledCheque" hidden />
                  </fieldset>
                </div>
                <div class="row">
                  <fieldset class="form-group col-md-6">
                    <label for="signing_authority">Signing Authority Name *</label>
                    <input type="text" v-model="new_registration.authority_name" class="form-control" placeholder="Enter Signing Authority Name" required />
                  </fieldset>
                  <fieldset class="form-group col-md-6">
                    <label for="bank_account_number" style="margin-bottom:10px;">Authority Signature *</label>
                    <VueSignaturePad required height='140px' id="signature" class="form-control" ref="signaturePad" :options="{ onEnd }" />
                    <p style="color:red;font-size: 12px;text-align:center;">{{SignatureErrorMsg}}</p>
                    <div class="col-lg-2 text-center mt-2">
                      <button type="button" @click="clearSignature" class="btn">clear</button>
                    </div>
                  </fieldset>
                </div>
                <div class="row submit-section">
                  <div class="col-lg-2">
                    <button type="submit" class="btn btn-block step-button">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <img src="@/assets/dsa-background.png" class="dsa-background-image" />
      <footer class="d-block d-md-none d-lg-none">
        <ul class="footer-links">
          <li class="nav-item">
            <a href="https://propertypistol.com/privacy-policy" target="_blank" class="nav-link">Privacy Policy</a>
          </li>
          <li class="nav-item b-none">
            <a href="https://propertypistol.com/user-agreement" target="_blank" class="nav-link">Terms and Conditions</a>
          </li>
          <li class="w-100 b-none"></li>
          <li class="nav-item">
            <a href="https://propertypistol.com/contact-us" target="_blank" class="nav-link">Contact Us</a>
          </li>
          <li class="nav-item  b-none">
            <a href="https://propertypistol.com" target="_blank" class="nav-link">© 2022 PropertyPistol Pvt. Ltd.</a>
          </li>
        </ul>
      </footer>
    </main>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cancelled_cheque_attributes: '',
      new_registration: {
        bank_name: null,
        account_no: null,
        account_type: null,
        cancelled_cheque: null,
        signature: null,
        authority_name: null,
        ifsc_code: null
      },
      fileErrorMessage: null,
      SignatureErrorMsg: null,
      AccountTypeErrorMsg: null,
    }
  },
  created() {
    let step3_data = this.$store.getters.newRegData
    if (step3_data) {
      this.new_registration = step3_data;
      if (this.new_registration.signature) {
        const signature = this.dataURIToBlob(this.new_registration.signature);
        const reader = new FileReader();
        reader.readAsDataURL(signature)
        reader.onloadend = () => {
          this.$refs.signaturePad.fromDataURL(reader.result)
        }
      }
      if (this.new_registration.cancelled_cheque) {
        this.cancelled_cheque_attributes = this.new_registration.cancelled_cheque.name;
      }
    }
  },
  computed:{
    rmList(){
      return this.$store.getters.registrationSettings && this.$store.getters.registrationSettings.users;
    },
    accountTypes(){
      return this.$store.getters.registrationSettings && this.$store.getters.registrationSettings.account_type;
    }
  },
  methods: {
    uploadCancelledCheque() {
      this.new_registration.cancelled_cheque = event.target.files[0];
      this.cancelled_cheque_attributes = this.new_registration.cancelled_cheque.name
      this.fileErrorMessage = null;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.new_registration.signature = null
    },
    onEnd() {
      let { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (!isEmpty) {
        this.new_registration.signature = data;
      }
    },
    step2Page() {
      this.$router.push({ name: 'Step2' });
    },
    dataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(',')
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], { type: mimeString })
    },
    createDsaRegistration() {
      let formData = new FormData();
      let { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (!isEmpty) {
        this.new_registration.signature = data;

      } else {
        this.SignatureErrorMsg = 'Please Add Authority Signature'
        return;
      }
      if (!this.new_registration.cancelled_cheque) {
        this.fileErrorMessage = "Cancelled cheque is mandatory";
        return;
      }
      if (!this.new_registration.account_type) {
        this.AccountTypeErrorMsg = "Please Select Account Type";
        return;
      }
      let finalSubmitData = this.$store.getters.newRegData
      let dsa_Data = { ...finalSubmitData, ...this.new_registration }
      formData.append('name', dsa_Data.name);
      if(dsa_Data.firm_name){
        formData.append('firm_name',dsa_Data.firm_name);
      }
      formData.append('mobile', dsa_Data.mobile);
      formData.append('email_id', dsa_Data.email_id);
      formData.append('aadhar_number', dsa_Data.aadhar_number);
      formData.append('registration_type', dsa_Data.registration_type);
      formData.append('is_gst_applicable', dsa_Data.is_gst_applicable === 'yes');
      if (dsa_Data.gst_declaration) {
        formData.append('gst_declaration', dsa_Data.gst_declaration);
      }
      if (dsa_Data.gst_cert) {
        formData.append('gst_certificate', dsa_Data.gst_cert || '');
      }
      formData.append('entity_name', dsa_Data.entity_name || '');
      if (dsa_Data.pan_card) {
        formData.append('pan_card', dsa_Data.pan_card);
      }
      if (dsa_Data.aadhar_card) {
        formData.append('aadhar_card', dsa_Data.aadhar_card);
      }
      if(dsa_Data.location){
        formData.append('location_id',dsa_Data.location);
      }
      if(dsa_Data.rm_field){
        formData.append('user_id',dsa_Data.rm_field);
      }
      formData.append('bank_detail_attributes[bank_name]', dsa_Data.bank_name);
      formData.append('bank_detail_attributes[bank_account_number]', dsa_Data.account_no);
      formData.append('bank_detail_attributes[ifsc_code]', dsa_Data.ifsc_code);
      formData.append('bank_detail_attributes[account_type]', dsa_Data.account_type || '');
      formData.append('bank_detail_attributes[cancelled_cheque]', dsa_Data.cancelled_cheque);
      formData.append('authority_name', dsa_Data.authority_name);
      const signature = this.dataURIToBlob(dsa_Data.signature);
      if (signature) {
        formData.append('signature', signature, 'signature');
      }
      this.$store.dispatch('setLoading', true);
      this.$axios
        .post("dsa/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          swal({
            title: "DSA Application Submitted!",
            icon: "success"
          }).then(() => this.$router.replace({ name: "Step1" }));
          this.$store.dispatch('setLoading', false);
          this.$store.dispatch('setNewRegistrationData', null);
        })
        .catch(err => {
          this.$store.dispatch('setLoading', false);
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        });
    },
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.btn-outline-dark {
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
}

.cursor {
  cursor: pointer;
}

#signature {
  padding: 0;
  margin: 0;
}
</style>