import { createStore } from "vuex";

export default new createStore({
  state: {
    newRegData: {},
    isLoading: false,
    registrationSettings:{}
  },
  mutations: {
    setNewRegistrationData(state, regData){
      state.newRegData = regData
    },
    setLoading(state, isLoading){
      state.isLoading = isLoading
    },
    setRegistrationSettings(state,settings){
      state.registrationSettings = settings;
    }

  },
  actions: {
    setNewRegistrationData({commit}, regData){
      commit('setNewRegistrationData', regData);
    },
    setLoading({commit}, isLoading){
      commit('setLoading', isLoading);
    },
    setRegistrationSettings({commit},settings){
      commit('setRegistrationSettings',settings);
    }
  },
  getters: {
    newRegData(state){
      return state.newRegData;
    },
    isLoading(state){
      return state.isLoading;
    },
    registrationSettings(state){
      return state.registrationSettings;
    }
  }
})
