<template>
<div>
    <main class="main-section">
    <section class="dsa-form">
      <div class="container">
        <span class="dsa-title">DSA Form Application</span>
        <div class="dsa-form-collapsible">
          <div class="form-step">
            <span class="step-heading" @click="step1Page()"><img src="@/assets/back-arrow.svg" width="16" height="16"
                class="img-fluid back-arrow" data-bs-target="#personal_details" />Step
              2/3</span>
            <h1 class="dsa-form-section-title">Entity Details for Payout </h1>
            <form @submit.prevent="goToStep3">
              <div class="row">
                <fieldset class="form-group radio-group" v-if="registrationSettings && registrationSettings.length > 0">
                  <label class="radio-group-label">Entity Registration For *</label>
                  <template v-for="setting in registrationSettings">
                    <input type="radio" class="btn-check" v-model="new_registration.registration_type" :value="setting.id" name="registration_for" :id="`registration_${setting.id}`"
                    autocomplete="off" required>
                    <label class="btn btn-secondary" :for="`registration_${setting.id}`">{{setting.name && setting.name.toLowerCase()  === 'huf' ? 'HUF ( Hindu Undivided Family)' : setting.name}}</label>
                  </template>
                </fieldset>
              </div>
              <div class="row">
                <!-- <fieldset class="form-group col-md-6">
                  <label for="pan_card">Pan Details *</label>
                  <input type="text" v-model="new_registration.pan_card"  class="form-control"
                    placeholder="Enter PAN Number" required @keyup="new_registration.pan_card = new_registration.pan_card?new_registration.pan_card.toUpperCase():''" pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}$" ref="pan_element" />
                </fieldset> -->
                 <fieldset class="form-group col-md-6">
                  <label for="aadhaar_upload" class="upload-link" :class="{'error':!!PanCardErrorMessage}"><img src="@/assets/upload-link.svg" class="">
                    {{PanCardErrorMessage ?PanCardErrorMessage :(pan_card_attributes ? pan_card_attributes: "Upload Pan Card Photo")}}</label>
                  <input type="file" ref="file" id="aadhaar_upload" hidden  @change="selectedFile" />
                </fieldset>
              </div>
              <div class="row">
                <fieldset class="form-group col-md-6" v-if="new_registration.registration_type && new_registration.registration_type.toLowerCase() != 'individual'">
                  <label for="pan_card">Entity Name *</label>
                  <input type="text" v-model="new_registration.entity_name"  class="form-control"
                    placeholder="Enter Entity Name" required />
                </fieldset>
              </div>
              <div class="row">
                <fieldset class="form-group col-md-6 radio-group">
                  <label class="radio-group-label">GST Applicable *</label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input cursor" v-model="new_registration.is_gst_applicable" @change="checkGstApplicableValidation" type="radio" name="gst_applicable" id="gst_yes" value="yes" required>
                    <label class="form-check-label cursor" for="gst_yes">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input cursor"  v-model="new_registration.is_gst_applicable" @change="checkGstApplicableValidation" type="radio" name="gst_applicable" id="gst_no" value="no" required>
                    <label class="form-check-label cursor" for="gst_no">No</label>
                  </div>
                </fieldset>
              </div>
              <div class="row" v-if="new_registration.is_gst_applicable === 'yes'">
                <fieldset class="form-group col-md-6">
                  <label for="gst_upload" class="upload-link" :class="{'error':fileErrorMessage}"><img src="@/assets/upload-link.svg" class="">
                    {{fileErrorMessage? fileErrorMessage :(gst_certificate_attributes ? gst_certificate_attributes: "Upload GST Certificate")}}</label>
                  <input type="file" ref="file" id="gst_upload" hidden @change="uploadGSTCertificate" />
                </fieldset>
              </div>
              <div class="row submit-section">
                <div class="col-lg-2">
                  <button type="submit" class="btn btn-block step-button">Next</button>
                </div>
                <div class="col-12 col-lg-10 mt-2">
                  <fieldset class="form-check form-switch" v-if="new_registration.is_gst_applicable === 'no'">
                    <input class="form-check-input cursor" v-model="new_registration.gst_declaration" type="checkbox" role="switch" id="share_GST_declaration" required>
                    <label class="form-check-label cursor" for="share_GST_declaration">Mandatory GST Declaration</label>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <img src="@/assets/dsa-background.png" class="dsa-background-image" />
    <footer class="d-block d-md-none d-lg-none">
      <ul class="footer-links">
        <li class="nav-item">
          <a href="https://propertypistol.com/privacy-policy" target="_blank" class="nav-link">Privacy Policy</a>
        </li>
        <li class="nav-item b-none">
          <a href="https://propertypistol.com/user-agreement" target="_blank" class="nav-link">Terms and Conditions</a>
        </li>
        <li class="w-100 b-none"></li>
        <li class="nav-item">
          <a href="https://propertypistol.com/contact-us" target="_blank" class="nav-link">Contact Us</a>
        </li>
        <li class="nav-item  b-none">
          <a href="https://propertypistol.com" target="_blank" class="nav-link">© 2022 PropertyPistol Pvt. Ltd.</a>
        </li>
      </ul>
    </footer>
  </main>
</div>

</template>

<script>
export default {
  data() {
    return {
      gst_certificate_attributes: '',
      pan_card_attributes: '',
      new_registration: {
        registration_type: null,
        pan_card: null,
        is_gst_applicable: null,
        gst_cert: null,
        entity_name:null,
        gst_declaration: null,
      },
      fileErrorMessage:null,
      PanCardErrorMessage: null,
    }
  },
  created() {
    let step2_data = this.$store.getters.newRegData
    if (step2_data) {
      this.new_registration = step2_data;
      if(this.new_registration.gst_cert){
        this.gst_certificate_attributes = this.new_registration.gst_cert.name;
      }
      if(this.new_registration.pan_card){
        this.pan_card_attributes = this.new_registration.pan_card.name;
      }
    }
  },
  methods: {
    checkGstApplicableValidation() {
      if (this.new_registration.is_gst_applicable === 'yes') {
        this.new_registration.gst_declaration = false
      } else {
        this.gst_certificate_attributes = null
        this.new_registration.gst_cert = null
      }
    },
    selectedFile() {
      this.new_registration.pan_card = event.target.files[0];
      this.pan_card_attributes = this.new_registration.pan_card.name;
      this.PanCardErrorMessage = '';
    },
    uploadGSTCertificate(event) {
      this.new_registration.gst_cert = event.target.files[0];
      this.gst_certificate_attributes = this.new_registration.gst_cert.name;
      this.fileErrorMessage=null;
    },
    goToStep3() {
      if(this.new_registration.is_gst_applicable === 'yes'){
        if(!this.new_registration.gst_cert){
          this.fileErrorMessage="GST certificate is mandatory";
          return;
        }
      }
      if(!this.new_registration.pan_card){
        this.PanCardErrorMessage="Please upload Pancard";
        return;
      };
      let step0_data = this.$store.getters.newRegData;
      this.new_registration.step_two_complete = true;
      let new_data = {...step0_data, ...this.new_registration}
      this.$store.dispatch('setNewRegistrationData', new_data);
      this.$router.push({name: 'Step3'});
    },
    step1Page(){
      this.$router.push({name: 'Step1'});
    }
  },
  computed:{
    registrationSettings(){
      return this.$store.getters.registrationSettings && this.$store.getters.registrationSettings.registration_types; 
    }
  }
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
}
</style>